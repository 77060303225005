export default {
    tripAndTravellerDetails: state => state.tripAndTravellerDetails,
    pendingTrips: state => state.pendingTrips,
    oneWayTrips: state => state.oneWayTrips,
    userSelectedTrip: state => state.userSelectedTrip,
    seats: state => state.seats,
    oneWayBookingMinutesTimer: state => state.oneWayBookingMinutesTimer,
    oneWayBookingShowTimer: state => state.oneWayBookingShowTimer,
    oneWayTotalAmount: state => state.oneWayTotalAmount,
    selectedInsuranceProvider: state => state.selectedInsuranceProvider,
    selectedInsuranceAmount : state => state.selectedInsuranceAmount,
    insuranceProvider: state => state.insuranceProvider,
    selectedInsurancePlan: state => state.selectedInsurancePlan
}