import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import "@mdi/font/css/materialdesignicons.css";
import "./assets/css/tailwind.css";
import "./assets/css/global.scss";
import "./assets/css/style.css";
import VueFeather from "vue-feather";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
  configure,
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import {
  required
} from "vee-validate/dist/rules";
import displaySnackbar from "../src/services/utils/snackbar";
import VueSplide from "@splidejs/vue-splide";
import VueApexCharts from "vue-apexcharts";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import VueScrollReveal from 'vue-scroll-reveal';
import * as VueGoogleMaps from "vue2-google-maps";

import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.use(VueScrollReveal, {
  class: 'v-scroll-reveal', // A CSS class applied to elements with the v-scroll-reveal directive; useful for animation overrides.
  duration: 900,
  scale: 1,
  distance: '60px',
});

dayjs.extend(LocalizedFormat);
dayjs().format("L LT");

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.use(VueSplide);
Vue.prototype.$displaySnackbar = displaySnackbar;

Vue.use(VueApexCharts);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBc2jwskked57nbmXhFrngq4krQQeFXKxU",
    libraries: "places", // necessary for places input
  },
});
Vue.component("apexChart", VueApexCharts);

Vue.component("vue-feather", VueFeather);
Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
extend("required", {
  ...required,
  message: "The {_field_} field is required",
});


// keycloak.init({
//   onLoad: 'login-required',  // or 'check-sso' for silent authentication
//   pkceMethod: 'S256',
//   checkLoginIframe: false}).then((authenticated) => {
//   if (!authenticated) {
//     window.location.reload();
//   } else {
//     new Vue({
//       store,
//       router,
//       vuetify,
//       render: h => h(App)
//     }).$mount('#app')
//     extend("required", {
//       ...required,
//       message: "The {_field_} field is required",
//     });
//   }
//
//   // Token Refresh
//   setInterval(() => {
//     keycloak.updateToken(70).catch(() => {
//       keycloak.logout();
//     });
//   }, 6000);
//
// }).catch(() => {
//   console.error('Authentication failed');
// });


configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
  },
});

localize("en", en);