export default {

    setAvailableVehiclesForHire({
        commit
    }, payload) {
        commit('SET_AVAILABLE_VEHICLES_FOR_HIRE', payload)
    },

    hiringDetails({
        commit
    }, payload) {
        commit("HIRING_DETAILS", payload);
    },

};