import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";


const state = {
    availableVehiclesForHire: [],
    hiringDetails: {
        vehicleSelected: {},
        pickUpLocation:{},
        dropOffLocation:{},
        travellerDetail:{
            address:{}
        },
        pickUpTime: ""
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};