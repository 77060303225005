import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";


const state = {
    showSnackBar: false,
    success: false,
    snackBarMessage: "",
    color: "",
    showMediumSnackBar: false,
    mediumSnackBarStatus: false
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
