import Vue from 'vue'
import Vuex from 'vuex'
import snackBar from "@/store/snackBar";
import booking from "@/store/booking";
import hire from "@/store/hire";
Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    snackBar,
    booking,
    hire
  }
})
