export default {
    displaySnackBar({ commit }, payload) {

        commit("SHOW_SNACKBAR", payload);

    },
    closeSnackBar({ commit }) {
        commit("TOGGLE_SNACKBAR", false)
    },
    showMediumSnackBar({ commit }, payload) {
        commit("SHOW_MEDIUM_SNACKBAR", payload);
    },
    closeMediumSnackBar({ commit }) {
        commit("TOGGLE_MEDIUM_SNACKBAR", false)
    },
};