export default {

    SET_AVAILABLE_VEHICLES_FOR_HIRE: (state, value) => {
        state.availableVehiclesForHire = value
    },
    HIRING_DETAILS: (state, value) => {
        sessionStorage.setItem("hiringDetails", JSON.stringify(value))
        state.hiringDetails = value
    },

};