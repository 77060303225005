import Vue from 'vue'
import VueRouter from 'vue-router'
// import keycloak from "@/services/keycloak";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SelectTrip',
    component: () => import( '@/views/booking/newFlow/NewSelectTrip.vue'),
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: "/404",
    name: "errorpage",
    component: () => import("@/components/reuseables/ErrorPage.vue")
  },
  {
    path: '/select-seats',
    name: 'SelectSeats',
    component: () => import(/* webpackChunkName: "about" */ '../views/booking/SelectSeats.vue')
  },
  {
    path: '/input-details',
    name: 'InputDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/booking/InputDetails.vue')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "about" */ '../views/booking/CheckOut.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '../views/RegisterView.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/ResetPasswordView.vue')
  },
  {
    path: '/newFlow-password',
    name: 'NewPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewPassword.vue')
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: () => import(/* webpackChunkName: "about" */ '../views/OnboardingView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/empty',
    name: 'Empty',
    component: () => import(/* webpackChunkName: "about" */ '../views/EmptyPage.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: "/old-trip",
    name: 'OldTrip',
    component: () => import( '@/views/booking/SelectTrip.vue')
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (!keycloak.authenticated) {
//       keycloak.login().then(r => {});
//     } else {
//       next();
//     }
//   } else {
//     next(); // make sure to always call next()!
//   }
// });

export default router
